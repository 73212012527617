<template>
  <div class="allmsgclass">
    <navigation :msg="msg"></navigation>
    <div class="information">
      <div class="in1">
        <label for="phone1">联系手机</label>
        <input id="phone1" v-model="phone" disabled placeholder="内容" />
      </div>
      <div class="in1">
        <label for="pass8">设置密码</label>
        <input
          id="pass8"
          type="password"
          v-model="pass"
          placeholder="请输入6-8位数字和字母组合"
        />
      </div>
      <div class="in1">
        <label for="pass9">确认密码</label>
        <input
          id="pass9"
          type="password"
          v-model="password"
          placeholder="请再次输入设置的密码"
        />
      </div>
      <div class="in1">
        <label for="code">请输入验证码</label>
        <input
          id="code"
          oninput="if(value.length>6)value=value.slice(0,6)"
          v-model="code"
          placeholder="验证码"
        />
        <div class="getcodeclass" @click="cligetcode">
          {{ getcode == "获取验证码" ? getcode : `请${getcode}秒后重发` }}
        </div>
      </div>
    </div>

    <div class="btdiv">
      <buttones class="thebu" :msg="bumsg"></buttones>
    </div>
  </div>
</template>
<script>
import buttones from "../../components/botton/index";
import { Dialog } from "vant";
import { Notify } from "vant";
var intervalID;
export default {
  components: {
    buttones,
  },
  data() {
    return {
      msg: "我的信息",
      bumsg: "保存",
      getcode: "获取验证码", //获取验证码
      phone: "", //手机号
      username: "", //姓名
      code: "", //验证码
      pass: "", //密码
      password: "", //确认密码
      usable_point:"", //可用积分
    };
  },
  created() {
    this.getjifen();
  },
  mounted() {
    //查询用户账户信息
    this.getuserinformation();
  },
  methods: {
    /**
     * 点击保存信息
     */
    clickmeth() {
      if (!this.ismsgtrue()) return;
      this.$http
        .post("/firm/v1/Channel/setPassword", {
          reqType: "userinfo",
          mobile_code: this.code,
          password: this.password,
        })
        .then((res) => {
          if (JSON.parse(res.data).msg == "success") {
            this.$toast("密码修改成功");
            setTimeout(() => {
              this.$router.go(-1);
            }, 500);
          } else {
            this.$toast("密码修改失败");
          }
        });
    },
    /**
     * 查询用户绑定的信息
     */
    getuserinformation() {
      this.$http
        .post("/firm/v1/Channel/see_userInfo", {
          reqType: "userinfo",
        })
        .then((res) => {
          let selectmsg = JSON.parse(res.data).data;
          this.phone = selectmsg.account;
          this.username = selectmsg.username;
        });
    },
    /**
     * 判断字符是否符合格式
     */
    ismsgtrue() {
      if (this.pass == "") {
        Notify({ type: "danger", message: "请输入密码" });
        return;
      }
      if (String(this.pass).length<6||String(this.pass).length>8) {
        Notify({ type: "danger", message: "请输入6-8位数字和字母组合" });
        return;
      }
      if (this.password == "") {
        Notify({ type: "danger", message: "请再次输入密码" });
        return;
      }
      if (this.pass != this.password) {
        Notify({ type: "danger", message: "两次输入的密码不同" });
        return;
      }
      if (this.code == "") {
        Notify({ type: "danger", message: "请输入验证码" });
        return;
      }
      return true;
    },
    /**
     * 计时器
     */
    timeing() {
      this.getcode = 60;
      intervalID = setInterval(() => {
        this.getcode--;

        if (this.getcode < 1) {
          clearInterval(intervalID);
          this.getcode = "获取验证码";
        }
      }, 1000);
    },

    /**
     * 停止计时器
     */
    destroyed() {
      clearInterval(intervalID);
    },
    /**
     * 查询积分
     */
    getjifen() {
      this.$http
        .post("/firm/v1/Userinfo/usablePoint", {
          reqType: "userinfo",
        })
        .then((res) => {
          this.usable_point = JSON.parse(res.data).data.usable_point;
        });
    },

    /**
     * 获取验证码
     */
    cligetcode() {
      if (this.getcode != "获取验证码") {
        return;
      }
      if (this.phone == "") {
        Dialog({ message: "请输入正确手机号" });
        return;
      } else if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        Dialog({ message: "请输入正确手机号" });
        return;
      }

      this.timeing();

      //获取验证码
      this.sendcode();
    },

    /**
     * 发送验证码
     */
    sendcode() {
      this.$http
        .post("/firm/v1/login/valid_send", {
          reqType: "login",
          mobile: this.phone,
          source: "currency",
		  login_type: 1
        })
        .then((res) => {
          this.$toast(JSON.parse(res.data).msg);
        });
    },
  },
};
</script>
<style scoped>
.myji {
  margin-top: 0.21rem;
  display: flex;
  align-items: center;
}
.myji img {
  width: 0.3rem;
  height: 0.3rem;
}
.jifen {
  font-size: 0.28rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #616b80;
  line-height: 0.4rem;
  margin-right: 0.13rem;
}
.btdiv {
  padding-bottom: 0.3rem;
}
input {
  background-color: white;
  -webkit-opacity: 1;
  opacity: 1;
}

#code {
  text-align: center;
  max-width: 2rem;
}
.getcodeclass {
  color: #f55613;
}
.thebu {
  margin-top: 2rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.information {
  margin: 0.59rem 0.54rem;
  font-size: 0.28rem;
  color: #697386;
}
.in1 {
  padding: 0.22rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
input {
  text-align: right;
  border: none;
  width: 4.5rem;
}
input::placeholder {
  color: #c1c5ce;
}
</style>