<template>
  <div>
    <button @click="clickmethes" class="bton">{{ msg }}</button>
  </div>
</template>
<script>
export default {
  props: {
    msg: String,
  },
  data(){
      return{
          cl:""
      }
  },
  mounted(){
      this.cl = this.click();
  },
  methods: {
    clickmethes() {
        this.cl(this);
    },
    click() {
      var 
      lock = true;
      return function (ch) {
          
        if(lock != true)return;
        lock = false
        ch.$parent.clickmeth();
        setTimeout(() => {
            
            lock = true
        }, 1000);
      };
    },
  },
};
</script>
<style scoped>
.bton {
  width: 90%;
  color: #ffffff;
  background: linear-gradient(to right, #f55613, #fc9e46);
  margin: 0 5%;
  border: none;
  border-radius: 0.8rem;
  font-size: 0.36rem;
  padding: 0.2rem;
  height: 0.96rem;
}
</style>